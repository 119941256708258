import "@hotwired/turbo-rails";
import "./bulma_methods.js";
import "./controllers";
import "trix"; // required for action text
import "@rails/actiontext";


document.addEventListener('turbo:load', () => {
  let dropdown = document.querySelector('.navigation .has-dropdown');

  if(dropdown) {
    dropdown.addEventListener("click", function(e) {
      this.children(".navbar-dropdown").toggle();
    });
  }

  if (window.innerWidth < 1024) {
    hideCards();
    swipeOpenAside();
  }
});

let asideOpen = true;
if(window.history.state && window.history.state.turbo) {
  window.addEventListener("popstate", () => { location.reload(true); })
}

document.addEventListener('turbo:frame-load', () => {
  init_dropdowns();
  multiselect();
});

['turbo:load'].forEach(evt => {
  document.addEventListener(evt, ()=> {
    multiselect();

    // sidebar close on link click
    const html = document.querySelector('html')
    html.classList.add('has-aside-mobile-transition');
    html.classList.remove('has-aside-mobile-expanded');

    const store_dropdown = document.querySelector('#current_store');
    if(store_dropdown) {
      store_dropdown.value = store_dropdown.dataset.currentStore;

      store_dropdown.addEventListener('change', (event)=>{
        event.preventDefault();
        store_dropdown.value = event.target.value;
        postData(('/admin/current_stores/' + store_dropdown.value ), {})
        .then(data =>  {
          const url = new URL(location.href);
          url.searchParams.set('store_id', event.target.value);

          location.assign(url.search);
        });
      });
    }
  });
})

function swipeOpenAside() {
  let touchStart, touchEnd;
  const sidebar = document.querySelector('aside');

  if(!sidebar) { return }
  const html = document.querySelector('html');
  const icon = document.querySelector('.jb-aside-mobile-toggle i');

  if(!icon) { return; }
  icon.addEventListener('click', (e) => {
    asideOpen = !asideOpen;

    if(asideOpen) {
      html.classList.remove('has-aside-mobile-expanded');
      icon.classList.add('mdi-forwardburger')
      icon.classList.remove('mdi-backburger')
    } else {
      html.classList.add('has-aside-mobile-expanded');
      icon.classList.remove('mdi-forwardburger')
      icon.classList.add('mdi-backburger')
    }
  })

  sidebar.addEventListener('touchstart', e => {
   (touchStart = e.targetTouches[0].clientX)
  })

  sidebar.addEventListener('touchmove', e => {
    (touchEnd = e.targetTouches[0].clientX)
  })

  sidebar.addEventListener('touchend', e => {
    if (touchStart - touchEnd > 150) {
      html.classList.remove('has-aside-mobile-expanded');
      icon.classList.add('mdi-forwardburger')
      icon.classList.remove('mdi-backburger')
      asideOpen = false
    } else if(touchEnd - touchStart > 150) {
      html.classList.add('has-aside-mobile-expanded');
      icon.classList.remove('mdi-forwardburger')
      icon.classList.add('mdi-backburger')
      asideOpen = true
    }
  })
}

function hideCards() {
  document.querySelectorAll('.hide-card').forEach(element => {
    const hideButton = element.querySelector('button')
    element.closest('.card').querySelector('.card-body').classList.add('tw-hidden')

    hideButton.addEventListener('click', (e) => {
      element.closest('.card').querySelector('.card-body').classList.toggle('tw-hidden')
    })
  })
}

async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data)
  });
  return response.json();
}

function init_dropdowns() {
  const dropdowns = document.querySelectorAll(".dropdown");

  const urlParams = new URLSearchParams(window.location.search);
  const dropdownTriggeredId = urlParams.get('dropdown_id');

  dropdowns.forEach((e) => {
    const trigger = e.querySelector('.dropdown-trigger');
    const content = e.querySelector('.dropdown-main')

    if(!trigger) return;
    trigger.addEventListener('click', (evt) => {
      trigger.children[0].classList.toggle('rotate-90')
      e.classList.toggle('is-active');
      content.classList.toggle('tw-hidden')
    })

    if(dropdownTriggeredId) {
      if(trigger.dataset.dropdownId == dropdownTriggeredId) { trigger.click(); }
    }
  })
}

function init_tabs() {
  let tabsWithContent = (function () {
    let tabs = document.querySelectorAll('.tabs li');
    let tabsContent = document.querySelectorAll('.tab-content');
    if(tabs.size > 0) { return -1 }

    let deactvateAllTabs = function () {
      tabs.forEach(function (tab) {
        tab.classList.remove('is-active');
      });
    };

    let hideTabsContent = function () {
      tabsContent.forEach(function (tabContent) {
        tabContent.classList.remove('is-active');
      });
    };

    let activateTabsContent = function (tab) {
      if(tabsContent[getIndex(tab)]) {
        tabsContent[getIndex(tab)].classList.add('is-active');
      }
    };

    let getIndex = function (el) {
      return [...el.parentElement.children].indexOf(el);
    };

    tabs.forEach(function (tab) {
      tab.addEventListener('click', function () {
        deactvateAllTabs();
        hideTabsContent();
        tab.classList.add('is-active');
        activateTabsContent(tab);
      });
    });

    const url = new URL(location.href);

    if(url.searchParams.get('tab')) {
      tabs.forEach((node) => { 
        if(node.id == url.searchParams.get('tab')) {
          node.click()
          return;
        }
      })
    } else {  tabs[0].click(); }
  })();
}

function multiselect() {
  let toggle = 0;
  const multi_select_box = document.getElementById('select-all-items');

  if(multi_select_box) {
    multi_select_box.addEventListener('click', (event) => {
      event.preventDefault();

      [].forEach.call(document.querySelectorAll(" input[type='checkbox']"), function(el) { el.checked = toggle; });
      toggle = !toggle;
    });
  }
}
