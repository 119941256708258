import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modal']

  hideModal() {
    this.modalTarget.classList.add('tw-hidden');
    this.modalTarget.classList.remove('tw-flex');
  }

  showModal() {
    this.modalTarget.classList.remove('tw-hidden');
    this.modalTarget.classList.add('tw-flex');
  }

  connect() {
    this.hideModal();

    const modalId = this.modalTarget.dataset.id;
    const paramModalId = new URLSearchParams(window.location.search).get('modal_id');

    if(this.modalTarget.dataset.hidedefault) {

      if(JSON.parse(this.modalTarget.dataset.hidedefault) == false && (modalId === paramModalId)) {
        this.showModal();
      } else { this.hideModal(); }
    }

    const clickTargetName = this.modalTarget.dataset.name;
    const clickTargets = document.querySelectorAll(`#${clickTargetName}`);

    const _self = this;

    if (clickTargets) {
      clickTargets.forEach(target => {
        target.addEventListener('click', () => {
          _self.showModal();
          tail.select(".multiselect", {
            search: true,
            descriptions: true,
            hideSelected: true,
            hideDisabled: true,
            multiLimit: 100,
            multiShowCount: false,
            multiContainer: true,
          });
        })
      })
    }

    this.modalTarget.addEventListener('click', (event) => {
      if(this.modalTarget === event.target) { this.hideModal(); }
    })

    window.dispatchEvent(new CustomEvent('modalInitializationComplete'));
  }
}
